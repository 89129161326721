<template>
  <div>
    <a-row class="pbz-font d-flex align-items-center">
      <a-col :span="1" class="d-flex justify-content-start">
        <div style="cursor: pointer" @click.prevent="back()">
          <ArrowIcon
            class="ant-dropdown-link"
            style="color: #0559cb; height: 100px; width: 25px"
            :rotate="'rotate(270)'"
            @click="(e) => e.preventDefault()"
          />
        </div>
      </a-col>
      <a-col
        class="d-flex justify-content-center align-items-center"
        :span="5"
        style="border-left: 1px solid rgb(204, 204, 204)"
      >
        <div style="color: #0559cb; margin-right: 5px">
          {{ $t('fulfillment.order_list') }} >
        </div>
        {{ $t('fulfillment.print_label') }}
      </a-col>
    </a-row>
    <a-row>
      <div
        class="pbz-font subtitle-sm-medium d-flex"
        style="margin: 20px 210px"
      >
        {{ $t('fulfillment.setting_label') }}
      </div>
      <a-col :span="14" class="d-flex justify-content-end">
        <div class="label-shipment">
          <div class="label-shipment-content">
            <div class="unboxing-warning" style="margin-bottom: 10px">
              <img src="@/assets/images/unboxing-warning.webp" width="100%" />
            </div>

            <!-- <a-col v-if="isAwbExist" :span="12">
              <div v-if="isAwbExist" style="margin-top: 10px">
                <VueBarcode v-model="hardcodeAWB" :options="barcode" />
              </div>
              <div v-if="isAwbExist" style="margin-bottom: 15px;">
                AWB: {{ hardcodeAWB }}
              </div>
            </a-col> -->
            <div class="label-shipment-content-border">
              <!-- <a-row style="border-bottom: 1px solid #cccccc">
                <a-col :span="12" class="d-flex">
                  <img
                    :src="tokopedia"
                    alt=""
                    style="height: 50px; margin: 5px 20px"
                  >
                </a-col>
                <a-col :span="12" class="d-flex justify-content-end">
                  <div
                    style="margin: 15px 20px; font-size: 18px; font-weight: bold"
                  >
                    Non Tunai
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="9" style="margin: 10px">
                  <div class="d-flex" style="margin-left: 10px; font-size: 11px;">
                    <div>
                      {{ order.order_number }}
                    </div>
                  </div>
                  <a-row class="d-flex" style="margin-top: 10px">
                    <a-col :span="15" style="max-height: 62px;  padding-top: 15px;">
                      <img
                        :src="getCourierLogo(shipping.courier)"
                        alt=""
                        style="max-height: 30px; max-width: 93px;"
                      >
                    </a-col>
                    <a-col :span="11">
                      <div class="d-flex" style="font-size: 13px">
                        {{ shipping.courier }}
                      </div>
                      <div class="d-flex " style="font-weight: bold; text-align: left; font-size: 13px">
                        {{ shipping.service_name }}
                      </div>
                      <div class="d-flex" style="font-weight: bold font-size: 13px">
                        package
                      </div>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col :span="14">
                  <div style="height: auto; justify-content: center; margin-top: 10px;">
                    <VueBarcode v-model="topBarcode" :options="barcode" />
                  </div>
                </a-col>
              </a-row>
              <a-row class="d-flex">
                <a-col :span="4">
                  <div style="font-size: 12px">
                    <div>Berat:</div>
                    <div style="font-weight: bold">
                      {{ product.uom }}
                    </div>
                  </div>
                </a-col>
                <a-col :span="6">
                  <div style="font-size: 12px;">
                    <div>Ongkir:</div>
                    <div style="font-weight: bold;">
                      Rp. {{ shipping.shipping_cost.value }}
                    </div>
                  </div>
                </a-col>
                <a-col :span="14">
                  <div style="font-size: 15px; font-weight: bold">
                    {{
                      shipping.awb !== null ? shipping.awb : shipping.booking_no
                    }}
                  </div>
                </a-col>
              </a-row> -->
              <div class="my-2">
                <a-row class="d-flex">
                  <a-col
                    :span="6"
                    style="margin: 0 10px; font-weight: bold; text-align: left; font-size: 12px;"
                  >
                    <div class="d-flex">
                      Kurir
                    </div>
                  </a-col>
                  <a-col
                    :span="18"
                    style="margin: 0 10px; font-weight: bold; text-align: left; font-size: 12px;"
                  >
                    <div class="d-flex">
                      : {{
                        [
                          shipping?.courier, shipping?.service_name
                        ].filter((item) => item).join(' - ')
                      }}
                    </div>
                  </a-col>
                </a-row>
                <a-row class="d-flex">
                  <a-col
                    :span="6"
                    style="margin: 0 10px; font-weight: bold; text-align: left; font-size: 12px;"
                  >
                    <div class="d-flex">
                      Ongkos Kirim
                    </div>
                  </a-col>
                  <a-col
                    :span="18"
                    style="margin: 0 10px; font-weight: bold; text-align: left; font-size: 12px;"
                  >
                    <div class="d-flex">
                      : {{ shipping.shipping_cost?.value | currency }}
                    </div>
                  </a-col>
                </a-row>
              </div>
              <template v-if="isAwbExist">
                <div class="d-flex justify-items-center text-center justify-content-center w-100">
                  <div class="d-flex flex-column w-100 mx-2 mb-1">
                    <div class="bold awb-tag-name py-2" style="margin-top: 0px">
                      <span style="font-weight: normal; letter-spacing: 0px; padding-right: 10px;">AWB:</span>{{ shipping.awb }}
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-items-center text-center justify-content-center mb-3 w-100">
                  <VueBarcode
                    v-model="shipping.awb"
                    :options="barcode"
                    style="max-width: 500px; max-height: 80px;"
                  />
                </div>
              </template>
              <a-row
                class="d-flex"
                style="
                  padding-top: 10px;
                  font-size: 12px;
                  text-align: left;
                  border-top: 1px dotted #cccccc;
                "
              >
                <a-col :span="24" style="margin: 0px 10px; text-align: left; font-size: 12px; width: fit-content;">
                  <div class="d-flex" style="font-weight: bold">
                    Pengirim: 
                    <span class="ml-2">
                      {{ sender?.name || shipment?.name || salesData?.business?.business_title || '' }}
                    </span>
                  </div>
                  <div class="d-flex">
                    {{ sender?.address || shipment?.address || '' }}
                  </div>
                  <div class="d-flex">
                    {{ sender?.phone || shipment?.phone || '' }}
                  </div>
                </a-col>
              </a-row>
              <a-row class="d-flex">
                <a-col :span="24" style="margin: 10px 10px 0; text-align: left; font-size: 12px; width: fit-content;">
                  <div class="d-flex" style="font-weight: bold">
                    Penerima
                  </div>
                  <div class="d-flex">
                    {{ destination?.name || '-' }}
                  </div>
                  <div class="d-flex">
                    <template v-if="destination.address">
                      {{ destination.address?.line1 }}
                      {{ destination.address?.line2 }},
                      {{ destination.address?.sub_district }},
                      {{ destination.address?.district }},
                      {{ destination.address?.province }},
                      {{ destination.address?.postal_code }}
                    </template>
                    <template v-else>
                      -
                    </template>
                  </div>
                  <div class="d-flex">
                    {{ destination?.phone || '-' }}
                  </div>
                </a-col>
              </a-row>
              <!-- <a-row
                class="d-flex justify-content-center"
                style="margin: 10px 0px"
              >
                <a-col :span="23" style="border: 1px solid #cccccc">
                  <div style="font-size: 12px">
                    penjual <b>tidak perlu</b> bayar apapun ke kurir, sudah
                    dibayarkan otomatis
                  </div>
                </a-col>
              </a-row>
              <a-row class="d-flex" style="padding-bottom: 40px">
                <a-col
                  :span="12"
                  style="margin: 0 10px; font-size: 12px; text-align: left"
                >
                  <div class="d-flex">
                    kepada
                  </div>
                  <div class="d-flex" style="font-weight: bold">
                    {{ destination.name }}
                  </div>
                  <div class="d-flex">
                    {{ destination.address.line1 }}
                    {{ destination.address.line2 }},
                    {{ destination.address.sub_district }},
                    {{ destination.address.district }},
                    {{ destination.address.province }},
                    {{ destination.address.postal_code }}
                  </div>
                  <div class="d-flex">
                    {{ destination.phone }}
                  </div>
                </a-col>
                <a-col
                  :span="12"
                  style="margin: 0 10px; font-size: 12px; text-align: left"
                >
                  <div class="d-flex">
                    Dari:
                  </div>
                  <div class="d-flex" style="font-weight: bold">
                    {{ shipment.name }}
                  </div>
                  <div class="d-flex">
                    {{ shipment.addres }}
                  </div>
                  <div class="d-flex">
                    {{ shipment.phone }}
                  </div>
                </a-col>
              </a-row> -->
              <div
                v-if="printFulfillmentId"
                style="
                  margin-top: 10px;
                  font-weight: 600;
                  font-size: 14px;
                  text-align: center;
                  border-top: 1px dotted #cccccc;
                "
              >
                <a-row :gutter="16">
                  <a-col :span="24">
                    <div v-if="isExist" style="margin-top: 10px">
                      <VueBarcode
                        v-model="order.order_number"
                        :options="barcode"
                        style="max-width: 500px; max-height: 80px;"
                      />
                    </div>
                    <div v-if="isExist" style="margin-bottom: 15px;">
                      No. Order: {{ order.order_number }}
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div
                style="
                  margin: 10px 0px;
                  padding-top: 10px;
                  font-size: 12px;
                  text-align: left;
                  border-top: 1px dotted #cccccc;
                "
              >
                <a-row class="d-flex">
                  <a-col
                    :span="6"
                    style="margin: 0 10px; font-weight: bold; text-align: left"
                  >
                    <div class="d-flex">
                      No Order
                    </div>
                  </a-col>
                  <a-col
                    :span="18"
                    style="margin: 0 10px; font-weight: bold; text-align: left"
                  >
                    <div class="d-flex">
                      : {{ order.order_number }}
                    </div>
                  </a-col>
                </a-row>
                <a-row class="d-flex">
                  <a-col
                    :span="6"
                    style="margin: 0 10px; font-weight: bold; text-align: left"
                  >
                    <div class="d-flex">
                      Tanggal Order
                    </div>
                  </a-col>
                  <a-col
                    :span="18"
                    style="margin: 0 10px; font-weight: bold; text-align: left"
                  >
                    <div class="d-flex">
                      : {{ parseISOString(order.order_date) }}
                    </div>
                  </a-col>
                </a-row>
                <!-- <a-row class="d-flex">
                  <a-col
                    :span="6"
                    style="margin: 0 10px; font-weight: bold; text-align: left"
                  >
                    <div class="d-flex">
                      Berat
                    </div>
                  </a-col>
                  <a-col
                    :span="18"
                    style="margin: 0 10px; font-weight: bold; text-align: left"
                  >
                    <div class="d-flex">
                      : 1200 gr
                    </div>
                  </a-col>
                </a-row> -->
                <a-row v-if="isShowValueOrder" class="d-flex">
                  <a-col
                    :span="6"
                    style="margin: 0 10px; font-weight: bold; text-align: left"
                  >
                    <div class="d-flex">
                      Sub Total Order
                    </div>
                  </a-col>
                  <a-col
                    :span="18"
                    style="margin: 0 10px; font-weight: bold; text-align: left"
                  >
                    <div class="d-flex">
                      : {{ salesData?.sub_total | currency }}
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div
                style="
                  margin: 10px 0px;
                  padding-top: 10px;
                  font-size: 12px;
                  text-align: left;
                  border-top: 1px dotted #cccccc;
                "
              >
                <a-row class="d-flex">
                  <a-col
                    :span="24"
                    style="margin: 0 10px; text-align: left"
                  >
                    <div class="d-flex">
                      Catatan :
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div
                v-if="printDetailProduk"
                style="
                  margin: 10px 0px;
                  font-size: 12px;
                  text-align: left;
                  border-top: 1px solid #cccccc;
                "
              >
                <a-row class="d-flex" style="width: 100%">
                  <a-col :span="20" style="margin: 10px 10px">
                    <div>Produk</div>
                  </a-col>
                  <a-col :span="2" style="margin: 10px 0">
                    <div>Jumlah</div>
                  </a-col>
                </a-row>
                <a-row v-for="(item, index) in product" :key="index" class="d-flex" style="width: auto;">
                  <a-col :span="20" style="margin: 1px 10px">
                    <div>{{ item.catalog_title }}</div>
                  </a-col>
                  <a-col :span="2" style="margin: 1px 0">
                    <div style="text-align: center">
                      <b>{{ item.quantity }}</b> {{ item.uom }}
                    </div>
                  </a-col>
                </a-row>
              </div>
            </div>
          </div>
        </div>
      </a-col>

      <!-- Box print -->
      <a-col :span="10" class="d-flex">
        <div class="box-print">
          <a-row class="pbz-font">
            <a-col
              class="d-flex"
              style="margin: 30px 30px"
            >
              {{ $t('fulfillment.paper_size') }}
            </a-col>
            <a-col
              class="d-flex"
              style="
                padding-bottom: 30px;
                margin: 30px 30px;
                border-bottom: 1px solid #cccccc;
              "
            >
              <a-radio-group v-model="size" :default-value="'a4'" @change="setPageSize(size)">
                <a-radio :value="'a4'">
                  A4
                </a-radio>
                <a-radio :value="'148mm 210mm'">
                  A5
                </a-radio>
                <a-radio :value="'105mm 148mm'">
                  A6
                </a-radio>
              </a-radio-group>
            </a-col>
            <a-col class="d-flex" style="margin: 30px 30px">
              <a-checkbox :default-checked="printDetailProduk" @change="setDetailProduk">
                {{ $t('fulfillment.print_details') }}
              </a-checkbox>
            </a-col>
            <a-col class="d-flex" style="margin: 30px 30px">
              <a-checkbox :default-checked="true" @change="setPrintFulfillmentId">
                Cetak dengan ID Pengiriman
              </a-checkbox>
            </a-col>
          </a-row>
          <a-row class="pbz-font d-flex justify-content-center">
            <a-col :span="22">
              <button class="accept-button-modal" onClick="window.print()">
                {{ $t('fulfillment.print_label') }}
              </button>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { getSales } from '@/api/sales'
import { getFulfillmentDetail } from '@/api/fulfillment'
import { getWarehouseById } from '@/api/warehouse'
import { getStock } from '@/api/inventory'
import ArrowIcon from '@/components/Icons/Arrow.vue'
import VueBarcode from '@chenfengyuan/vue-barcode'
import getQueryParam from '@/utils/getQueryParam'
import { getBusinessMetaData } from '@/api/business'

export default {
  name: 'FulfillmentLabel',
  components: {
    ArrowIcon,
    VueBarcode,
  },
  mixins: [getQueryParam],
  data: function () {
    return {
      tokopedia: require('@/assets/integration/channel/tokopedia.png'),
      sicepat: require('@/assets/courier/sicepat.png'),
      anteraja: require('@/assets/courier/anteraja.png'),
      jne: require('@/assets/courier/jne.png'),
      sap: require('@/assets/courier/sap.png'),
      paxel: require('@/assets/courier/paxel.png'),
      detail: [],
      product: [],
      fulfillmentDataRAW: null,
      destination: [],
      shipping: {},
      warehouseId: '',
      size: '',
      printDetailProduk: this.$route.params.label_product,
      printFulfillmentId: true,
      shipment: {},
      order: {},
      sender: {},
      salesData: null,
      showValueOnShippingLabel: [],
    }
  },
  computed: {
    barcode() {
      return {
        height: 80,
        width: 1.5,
        displayValue: false,
      }
    },
    barcodeSmall() {
      return {
        height: 40,
        width: 1.5,
        displayValue: false,
      }
    },
    topBarcode() {
      return this.shipping.awb !== null ? this.shipping.awb : this.shipping.booking_no
    },
    isExist() {
      return this.detail[0] && this.detail[0].fulfillment_number
    },
    isAwbExist() {
      return Boolean(this.shipping?.awb)
    },
    isShowValueOrder() {
      const { order: detailOrder } = this.fulfillmentDataRAW
      return Boolean(this.showValueOnShippingLabel.includes(detailOrder?.channel?.id))
    },
  },
  mounted() {
    this.fetchDetailFulfillmentBasedOrderId()
  },
  methods: {
    parseISOString(dateTime) {
      if(dateTime) {
        const split = dateTime.split(/\D+/)
        const newDate = new Date(
          split[0], --split[1], split[2], split[3], split[4], split[5], split[6],
        )
        return this.$moment(newDate).format('Do MMMM YYYY')
      } else { return '' }
    },
    getCourierLogo(param) {
      switch (param.toLowerCase()) {
        case 'sicepat' :
          return this.sicepat
        case 'anteraja' :
          return this.anteraja
        case 'jne' :
          return this.jne
        case 'sap' :
          return this.sap
        case 'paxel' :
          return this.paxel
      }
    },
    setDetailProduk(e) {
      this.printDetailProduk = e.target.checked
    },
    setPrintFulfillmentId(e) {
      this.printFulfillmentId = e.target.checked
    },
    setPageSize(value) {
      const style = document.createElement('style')
      style.innerHTML = `@page {size: ${value}}`
      document.head.appendChild(style)
    },
    async fetchDetailFulfillmentBasedOrderId() {
      await getFulfillmentDetail({
        url: 'fulfillment',
        fulfillment_id: this.$route.query.fulfillment_id,
      })
      .then(({ data: { data: response } }) => {
        this.fulfillmentDataRAW = response
        this.detail = response.fulfillments
        this.order = response.order
        this.getDataDetail()
        this.getWarehouseId()
        this.fetchBusinessMetaData()
      })
      .catch((err) => console.error(err))
    },
    fetchBusinessMetaData() {
      getBusinessMetaData({
        business_id: this.fulfillmentDataRAW.business_id,
      })
      .then(({ data: response }) => {
        let findMetaValue
        response.forEach((item) => {
          if (item.meta_name === 'show_value_on_shipping_label') {
            findMetaValue = JSON.parse(item.meta_value)
          }
        })
        if (findMetaValue) {
          this.showValueOnShippingLabel = findMetaValue.channel_id
        } else {
          this.showValueOnShippingLabel = []
        }
      })
      .catch((err) => console.error(err))
    },
    async getWarehouseId() {
      try {
        const { data: response } = await getStock({
          catalog_id: this.product[0].catalog_id,
        })
        this.warehouseId = response.data?.[0] ? response.data?.[0].warehouse_id : ''
        this.fetchWarehouseId()
      } catch (error) {
        this.showErrorMessage = true
        this.$notification.error({
          message: error.message,
        })
      }
    },
    async fetchWarehouseId() {
      await getWarehouseById(this.warehouseId)
      .then(({ data: { data: response } }) => this.shipment = response)
      .catch((err) => console.error(err))
    },
    getDataDetail() {
      const { query } = this.$route
      const {
        fulfillment_id,
        workspace_id,
      } = query
      const data = this.detail.find((item) => item.id === fulfillment_id)
      this.product = data.line_items
      this.destination = data.destination
      this.shipping = data.shipping
      this.sender = data.sender

      this.controller = new AbortController()

      const payload = {
        signal: this.controller.signal,
        params: {
          business_id: this.fulfillmentDataRAW.business_id,
          workspace_id,
          page: 1,
          limit: 20,
          sort_mode: 'DESC',
          q: this.order.order_number,
        },
      }

      getSales(payload)
        .then(({ data: response }) => {
          if (response.total_row > 0) {
            const findSalesByOrderNumber = response.data.find((item) => item.order_number === this.order.order_number)
            if (findSalesByOrderNumber) {
              this.salesData = findSalesByOrderNumber
            } else {
              this.salesData = null
            }
          } else {
            this.salesData = null
          }
        })
        .catch((err) => console.error(err))

    },
    back() {
      this.$router.push({
        name: this.$route.params.id === undefined ? 'fulfillment.index' : 'channel-fulfillment',
        query: {
          ...this.getQueryParam(this.$route.query),
        },
      })
    },
  },
}
</script>

<style  lang="scss">
// $pageSize: 148mm 105mm;

.label-shipment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;

  position: absolute;
  min-width: 599px;
  width: auto;
  height: auto;

  /* Global Color/White */

  background: #ffffff;
  /* Global Color/Grey/Grey-100 */

  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;
  .label-shipment-content {
    min-width: 501px;
    max-width: 525px;
    width: auto;
    height: auto;
    box-sizing: border-box;
  }
  .label-shipment-content-border {
    border: 1px solid #000000;
  }
}
.box-print {
  position: absolute;
  width: 345px;
  height: auto;
  margin-left: 40px;

  /* Global Color/White */

  background: #ffffff;
  /* Global Color/Grey/Grey-100 */

  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 8px;

  .ant-radio-wrapper {
    color: #0559CB !important;
  }
}

.accept-button-modal {
  margin: 10px 0px;
  border: 1px solid #0559cb;
  background: #0559cb;
  color: #ffffff;
  width: 100%;
  padding: 0.75rem;
  border-radius: 5px;
  cursor: pointer;
}
.awb-tag-name {
  border: 1px solid #000000;
  width: 100%;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 14px;
}
@media print {
  // @page {
  //   size: a4;
  // }
  body * {
    visibility: hidden;
  }
  .label-shipment * {
    visibility: visible;
  }
  .label-shipment {
    position: fixed;
    margin: 0;
    left: 0;
    top: 0;
  }
}
</style>
